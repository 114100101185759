<template>
  <b-modal
      id="modal-expense-update"
      title="Редактирование платежа"
      ok-title="Сохранить"
      @ok="update"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label>Тип платежа</label>
          <v-select v-model="form.direction" :clearable="false" :options="paymentTypes"/>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <label>Касса</label>
          <v-select v-model="form.money_account" :options="bankAccounts" :clearable="false" label="name" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Затрата</label>
      <v-select v-model="form.cost" :options="costs" label="name" :clearable="false" />
    </div>
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label>Сумма</label>
          <input class="form-control" v-model="form.amount">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Валюта</label>
          <v-select v-model="form.currency" :options="currencies" :clearable="false" label="name" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Описание платежа</label>
      <textarea class="form-control" v-model="form.comments">
      </textarea>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'ExpenseUpdateModal',
  props: ['id'],
  components: {
    BModal, vSelect,
  },
  data() {
    return {
      paymentTypes: [
        { value: 'in', label: 'Приход' },
        { value: 'out', label: 'Расход' },
      ],
      form: {
        direction: null,
        cost: null,
        amount: null,
        currency: null,
        money_account: null,
      },
      costs: [],
      currencies: [],
      bankAccounts: [],
    };
  },
  watch: {
    async id() {
      await this.getPaymentInfo();
    },
  },
  async mounted() {
    await this.getCurrencies();
    await this.getMoneyAccounts();
    await this.getCosts();
  },
  methods: {
    async getPaymentInfo() {
      const response = await this.$api.expenses.get(this.id);
      this.form.direction = this.paymentTypes.find((type) => type.value === response.data.direction);
      this.form.amount = response.data.sum;
      this.form.comments = response.data.comments;
      this.form.cost = this.costs.find((cost) => cost.id === response.data.cost_id);
      this.form.currency = this.currencies.find((currency) => currency.id === response.data.currency_id);
      this.from.money_account = this.bankAccounts.find((account) => account.id === response.data.money_account_id);
    },
    async update() {
      await this.$api.expenses.update(this.id, this.form);
      this.$emit('update');
    },
    async getCosts() {
      this.costs = (await this.$api.costs.list()).data;
    },
    async getCurrencies() {
      this.currencies = (await this.$api.currencies.list()).data;
      this.form.currency = this.currencies.find((currency) => currency.id === 2);
    },
    async getMoneyAccounts() {
      this.bankAccounts = (await this.$api.bankAccounts.list()).data;
      this.form.money_account = this.bankAccounts.find((account) => account.id === 9);
    },
  },
};
</script>

<style scoped>

</style>
