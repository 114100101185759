<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2"></div>
            <div class="mx-2 mb-2">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <button v-b-modal.modal-expense-create class="btn btn-primary">
                      Новый платеж
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ expenses.from }} по {{ expenses.to }} из {{ expenses.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="expenses.total"
                      :per-page="expenses.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Дата</th>
                  <th>Затрата</th>
                  <th>Счет</th>
                  <th>Сумма</th>
                  <th>Статус</th>
                  <th>Комментарий</th>
                  <th colspan="2">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="payment in expenses.data" :key="payment.id">
                  <td class="font-weight-bold">#{{ payment.id }}</td>
                  <td>{{ $formatters.formattedDate(payment.created_at) }}</td>
                  <td>{{ payment.cost.name }}</td>
                  <td>{{ payment.payment_account.name }}</td>
                  <td>
                    <span :class="['amount', payment.direction === 'in' ? 'positive' : 'negative']">
                      {{ payment.sum }}</span> <span class="currency">{{ payment.currency.name }}
                  </span>
                  </td>
                  <td>
                    <b-avatar :id="`payment-row-${payment.id}`" size="32" :variant="`light-success`">
                      <feather-icon :icon="`SendIcon`"/>
                    </b-avatar>
                    <b-tooltip :target="`payment-row-${payment.id}`" placement="top">
                      <p class="mb-0">Оплачено</p>
                    </b-tooltip>
                  </td>
                  <td :class="payment.comments && payment.comments.length > 25 && 'font-small-2'">
                    <span :class="payment.comments && payment.comments.length > 20 && 'text-dashed'">
                      {{ payment.comments && payment.comments.length > 25 ? payment.comments.substring(0, 25) + '...' : payment.comments }}
                    </span>
                  </td>
                  <td>
                    <button @click="openUpdateModal(payment.id)" class="btn btn-primary">
                      Редактировать
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-danger" @click="deletePayment(payment.id)">
                      Удалить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ expenses.from }} по {{ expenses.to }} из {{ expenses.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="expenses.total"
                      :per-page="expenses.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
    <ExpenseCreateModal @update="getExpenses"/>
    <ExpenseUpdateModal :id="selectedPaymentId" @update="getExpenses"/>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BPagination, BTooltip,
} from 'bootstrap-vue';
import ExpenseCreateModal from '@/views/expenses/ExpenseCreateModal.vue';
import ExpenseUpdateModal from '@/views/expenses/ExpenseUpdateModal.vue';

export default {
  name: 'Expenses',
  components: {
    BCard, BAvatar, BPagination, BTooltip, ExpenseCreateModal, ExpenseUpdateModal,
  },
  data() {
    return {
      currentPage: 1,
      statuses: [],
      expenses: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
      selectedPaymentId: null,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.expenses.current_page = newValue;
      await this.getExpenses();
    },
  },
  async mounted() {
    await this.getExpenses();
  },
  methods: {
    async getExpenses() {
      this.expenses = (await this.$api.expenses.list({
        ...(this.expenses.current_page > 1 && { page: this.expenses.current_page }),
        limit: 25,
      })).data;
    },
    openUpdateModal(id) {
      this.selectedPaymentId = id;
      this.$bvModal.show('modal-expense-update');
    },
    async deletePayment(id) {
      this.$swal({
        title: 'Вы уверены?',
        text: 'Вы не сможете это вернуть назад!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, подтверждаю!',
        cancelButtonText: 'Нет, отмена!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$api.expenses.delete(id);
          await this.getExpenses();
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Платеж был успешно удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
